import React from 'react'

import './projects.styles.scss'

const Projects = () => (
  <div>
    Projects Page
  </div>
)

export default Projects
