import React from 'react'

import './blog.styles.scss'

const Blog = () => (
  <div>
    Blog Page
  </div>
)

export default Blog
