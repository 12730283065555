import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: "AIzaSyBr36pjTNV-PNlJFBXGzeJdz9ssLp_9HFI",
  authDomain: "christian-baker-dev.firebaseapp.com",
  databaseURL: "https://christian-baker-dev.firebaseio.com",
  projectId: "christian-baker-dev",
  storageBucket: "christian-baker-dev.appspot.com",
  messagingSenderId: "601380003108",
  appId: "1:601380003108:web:e34e1a6cf0b1b9a03467b1",
  measurementId: "G-Q4VVR611B4"
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return

  
}

firebase.initializeApp(config)

export const auth = firebase.auth()
export const firestore = firebase.firestore()

const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })
export const signInWithGoogle = () => auth.signInWithPopup(provider)

export default firebase