import React from 'react'

import './experience.styles.scss'

const Experience = () => (
  <div>
    Experience Page
  </div>
)

export default Experience
