import React from 'react'

import './homepage.styles.scss'

import { ReactComponent as JavaScriptLogo } from '../../assets/javascript-logo.svg'
import { ReactComponent as PythonLogo } from '../../assets/python-logo.svg'
import { ReactComponent as CSharpeLogo } from '../../assets/c-sharp-logo.svg'
import { ReactComponent as ReactLogo } from '../../assets/react-icon.svg'
import { ReactComponent as DjangoLogo } from '../../assets/django-logo.svg'
import { ReactComponent as XamarinLogo } from '../../assets/xamarin-logo.svg'

const HomePage = () => (
  <div className='homepage'>
    <div className='introduction'>
      <label>Hi, I'm </label><label className='my-name'>Christian</label>
    </div>
    <div className='hitter-phrase'>
      <label>I want to build all the things.</label>
    </div>
    <div className='languages-container'>
      <JavaScriptLogo className='language-image' />
      <PythonLogo className='language-image' />
      <CSharpeLogo className='language-image' />
    </div>
    <div className='frameworks-container'>
      <ReactLogo className='framework-image' />
      <DjangoLogo className='framework-image' />
      <XamarinLogo className='framework-image' />
    </div>
  </div>
)

export default HomePage
