import React from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../../firebase/firebase.utils'

import { ReactComponent as Logo } from '../../assets/home-icon.svg'

import './header.styles.scss'

const Header = ({ currentUser, showLogo }) => (
  <div className='header'>
    <Link className='logo-container' to="/">
      {
        showLogo
        ? <Logo className='logo' />
        : null
      }
    </Link>
    <div className='options'>
      <Link className='option' to='/'>HOME</Link>
      <Link className='option' to='/experience'>EXPERIENCE</Link>
      <Link className='option' to='/projects'>PROJECTS</Link>
      <Link className='option' to='/blog'>BLOG</Link>
    </div>
  </div>
)

export default Header
